import React, { useState, useEffect } from 'react';
import { User, CreditCard, LogOut, Edit, Save } from 'lucide-react';

// Profile Edit Component
const ProfileEditModal = ({ user, onClose, onSave }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    onSave(editedUser);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl p-6 w-96">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Edit Profile</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-2">Full Name</label>
            <input
              type="text"
              name="name"
              value={editedUser.userName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={editedUser.userEmail}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={editedUser.phone || ''}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Address</label>
            <textarea
              name="address"
              value={editedUser.address || ''}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <button 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button 
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
          >
            <Save className="mr-2" size={20} /> Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

const SubscribedLinksModel = ({ ObjectSubscribed, userDetails }) => {

  return (
    <div className="inset-0 bg-gray bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl p-6 w-96">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">{ObjectSubscribed.Heading}</h2>
        {/* <div className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-2">Full Name</label>
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Email</label>
            
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Phone Number</label>
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Address</label>
          </div>
        </div> */}
        {
          ObjectSubscribed.SubHeading.map((element, index) => 
          (
            <div className="flex justify-end space-x-4 mt-6">
          <button 
            onClick={() => {
              let targetWindow = window.open(ObjectSubscribed.Links[index]);
              let req = {
                // userId: userDetails.userId
              };
              // targetWindow.close();
              // targetWindow = window.open(ObjectSubscribed.Links[index]);
              req.userId = userDetails.userId;
              console.log(userDetails);
              console.log(JSON.stringify(req) + ObjectSubscribed.Links[index]);
              
              // Send the message after the child window is loaded
            setTimeout(() => {
              targetWindow.postMessage(req, ObjectSubscribed.Links[index]);
            }, 1000);
              
              // targetWindow?.postMessage(JSON.stringify(req), ObjectSubscribed.TargetLink);
            }}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          >
            {element}
          </button>
          {/* <button 
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
          >
            <Save className="mr-2" size={20} /> Save Changes
          </button> */}
        </div>
          ))
        }
      </div>
    </div>
  );
};

const Dashboard = ({userDetails}) => {
  // State to manage user information and subscription
  console.log(userDetails);
  const [user, setUser] = useState({
    // userName: '',
    // userEmail: '',
    // phone: '',
    // address: '',
    // isSubscribed: false
    ...userDetails
  });

  const subsribedObject = [
    {
      Heading: "Individual",
      SubHeading:[
        "Term loan",
        "Cash credit",
        "Term loan & cash credit"
      ],
      Links: [
        "https://termloan.lblpr.com",
        "https://cashcredit.lblpr.com",
        "https://lblpr.com"
        // "#cashCreditIndividual",
        // "#termLoan&cashCreditIndividual"
      ]
    },
    {
      Heading: "Partnership",
      SubHeading:[
        "Term loan",
        "Cash credit",
        "Term loan & cash credit"
      ],
      Links: [
        "https://lblpr.com",
        "https://lblpr.com",
        "https://lblpr.com"
        // "#termLoanPartnership",
        // "#cashCreditPartnership",
        // "#termLoan&cashCreditPartnership"
      ]
    }
  ];

  // State for managing profile edit modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Simulated user data persistence
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Save user changes
  const saveUserChanges = (updatedUser) => {
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  // Handle subscription toggle
  const toggleSubscription = () => {
    const updatedUser = {
      ...user,
      isSubscribed: !user.isSubscribed
    };
    saveUserChanges(updatedUser);
  };

  // Handle navigation to credit monitoring app
  const navigateToCreditApp = () => {
    if (user.isSubscribed) {
      // window.location.href = '/credit-monitoring';
    } else {
      alert('Please subscribe to access the Credit Monitoring App');
    }
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100">
      {/* Edit Profile Modal */}
      {isEditModalOpen && (
        <ProfileEditModal 
          user={user} 
          onClose={() => setIsEditModalOpen(false)}
          onSave={saveUserChanges}
        />
      )}

      {/* Header */}
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-blue-800">Credit Monitoring Analysis Dashboard</h1>
          <button 
            onClick={handleLogout}
            className="flex items-center bg-red-500 hover:bg-red-600 px-3 py-2 rounded text-white"
          >
            <LogOut className="mr-2" size={20} /> Logout
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Profile Section */}
          <div className="md:col-span-1 bg-white rounded-lg shadow-lg p-6">
            <div className="flex flex-col items-center">
              <div className="bg-blue-100 rounded-full p-4 mb-4">
                <User size={64} className="text-blue-600" />
              </div>
              <h2 className="text-xl font-semibold text-gray-800">{user.userName}</h2>
              <p className="text-gray-600 mb-4">{user.userEmail}</p>
              
              {/* Profile Details */}
              <div className="w-full bg-gray-50 rounded-lg p-4">
                <div className="mb-2">
                  <span className="font-medium text-gray-700">Phone:</span>
                  {/* <p className="text-gray-600">{user.phone}</p> */}
                </div>
                <div>
                  <span className="font-medium text-gray-700">Address:</span>
                  {/* <p className="text-gray-600">{user.address}</p> */}
                </div>
              </div>

              {/* Edit Profile Button */}
              <button 
                onClick={() => setIsEditModalOpen(true)}
                className="mt-4 w-full flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
              >
                <Edit className="mr-2" size={20} /> Edit Profile
              </button>
            </div>
          </div>

          {/* Dashboard Actions */}
          <div className="md:col-span-1 space-y-6">
            {/* Subscription Card */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Subscription Management</h3>
              
              {/* Credit Monitoring App Access */}
              <div className="mb-4">
                <button 
                  onClick={navigateToCreditApp}
                  className={`w-full flex items-center justify-center p-4 rounded-lg ${
                    user.isSubscribed 
                      ? 'bg-green-500 hover:bg-green-600 text-white' 
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                  disabled={!user.isSubscribed}
                >
                  <CreditCard className="mr-2" /> 
                  {user.isSubscribed ? 'Access Credit Monitoring Analysis App' : 'Subscribe to Access'}
                </button>
              </div>

              {/* Subscription Toggle */}
              {!user.isSubscribed ? (
                <button 
                  onClick={toggleSubscription}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white p-4 rounded-lg"
                >
                  Subscribe to Credit Monitoring Analysis
                </button>
              ) : (
                <button 
                  onClick={toggleSubscription}
                  className="w-full bg-red-500 hover:bg-red-600 text-white p-4 rounded-lg"
                >
                  Unsubscribe from Credit Monitoring Analysis
                </button>
              )}
              </div>
          </div>
        </div>
        <div className="md:col-span-1 space-y-6">
              {user.isSubscribed &&
              (subsribedObject.map((element) => (
                <>
                <SubscribedLinksModel ObjectSubscribed={element} userDetails={userDetails}/>
            <br/>
            </>
          )))} 
            </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white p-4 text-center">
        <p>&copy; 2024 Credit Monitoring Analysis Dashboard</p>
      </footer>
    </div>
  );
};

export default Dashboard;