import React, { useState } from 'react';
import './App.css'
import Dashboard from './DashBoard';

// Custom navigation context to manage routing
const NavigationContext = React.createContext(null);

// Custom Link component to manage navigation
const Link = ({ to, children, className }) => {
  const { navigate } = React.useContext(NavigationContext);
  return (
    <span 
      onClick={() => navigate(to)} 
      className={`cursor-pointer ${className}`}
    >
      {children}
    </span>
  );
};

// Main Login Page Component
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  // const [token, setToken] = useState('');
  // const [userId, setUserId] = useState(-1);
  const [userDetails, setUserDetails] = useState({});
  const [currentPage, setCurrentPage] = useState('login');

  // Navigation handler
  const navigate = (page) => {
    setCurrentPage(page);
  };

  const checkUserLoginDetails = async () => {
    try {
      let reqBody = {};
      reqBody.password = password;
      reqBody.userName = email;
      const response = await fetch("https://lblpr.com/httpapi/v1/creditMonitoringAnalysis/login",{method:"POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
  }).then((response) =>
 { 
  console.log(response);
  if(response.ok){
    console.log("Success");
  }else{
    console.log("Failure");
  }
  return response.json();
 }
).then((data) => {
  // console.log(data.body);
  if(data.Message === 'Success'){
    // setToken(data.Token);
    // setUserId(data.UserId);
    let userDetails = {};
    userDetails.Token = data.Token;
    userDetails.userId = data.UserId;
    setUserDetails(userDetails); 
    getUserDetail(userDetails);
    // navigate("dashboard");
    console.log(currentPage);
    return '';
  }else{
    return '*Incorrect username or password';
  }
});
console.log(response);
console.log(userDetails);
return response;
    } catch (error) {
      alert("error");
    }
    return '';
  }

  const getUserDetail = async (reqBody) => {
    try {
      // let reqBody = {};
      // reqBody.Token = userDetails.Token;
      // reqBody.userId = userDetails.UserId;
      // reqBody.emailId = email;
      const response = await fetch("https://lblpr.com/httpapi/v1/creditMonitoringAnalysis/getUserDetails",{method:"POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
  }).then((response) =>
 { 
  console.log(response);
  if(response.ok){
    console.log("Success");
  }else{
    console.log("Failure");
  }
  return response.json();
 }
).then((data) => {
  console.log(data.UserDetail);
  if(data.Message === 'Success'){
    // setToken(data.Token);
    // setUserId(data.UserId);
    // console.log(userDetails);
    let userDetail = {...userDetails};
    setUserDetails({...userDetail, ...data.UserDetail}); 
    navigate("dashboard");
    console.log(currentPage, userDetails);
    return '';
  }else{
    return data.ErrorMessage;
  }
});
console.log(response);
console.log(userDetails);
return response;
    } catch (error) {
      alert("error");
    }
    return '';
  }

  // Login handler
  const handleLogin = async (e) => {
    e.preventDefault();
    // TODO: Implement actual login logic
    // console.log('Login attempted with:', { email, password });
    const message = await checkUserLoginDetails();
    setMessage(message);
    // console.log(userDetails);
    const userMessage = await getUserDetail(userDetails);
    console.log(userMessage); 
    // console.log(userDetails);
    // Example of navigation after successful login
    // navigate('dashboard');
  };

  const saveUserDetails = async () => {
    try {
      let reqBody = {};
      reqBody.userName = fullName;
      reqBody.password = password;
      reqBody.emailId = email;
      const response = await fetch("https://lblpr.com/httpapi/v1/creditMonitoringAnalysis/signup",{method:"POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
  }).then((response) =>
 { 
  console.log(response);
  if(response.ok){
    console.log("Success");
  }else{
    console.log("Failure");
  }
  return response.json();
 }
).then((data) => {
  console.log(data);
  if(data.Message === 'Success'){
    // setToken(data.Token);
    // setUserId(data.UserId);
    let userDetails = {};
    userDetails.Token = data.Token;
    userDetails.userId = data.UserId;
    setUserDetails(userDetails); 
    navigate("dashboard");
    console.log(currentPage);
    return 'Success';
  }else{
    return '*Email Id is already in use..';
  }
});
console.log(response);
console.log(userDetails);
return response;
    } catch (error) {
      alert("error");
    }
    return '';
  }

//   const checkMailId = async () => {
//     try {
//       let reqBody = {};
//       reqBody.emailId = email;
//       const checkMailIdresponse = await fetch("http://localhost:8081/httpapi/v1/creditMonitoringAnalysis/checkMailId",{method:"POST",
//       mode:"cors",
//       headers: {
//         "Content-Type": "application/json",
//         },
//         body: JSON.stringify(reqBody)
//   }).then((response) =>
//  { 
//   console.log(response);
//   if(response.ok){
//     console.log("Success");
//   }else{
//     console.log("Failure");
//   }
//   return response.json();
//  }
// ).then((data) => {
//   if(data.message === 'Success'){
//     setMessage('*Email Id is already in use..');
//     navigate('signup');
//     return 'Email Id is already in use'
//   }else{
//     return 'Mail Id is new..';
//   }
// });
// console.log(checkMailIdresponse);
// return checkMailIdresponse;
//      } catch (error) {
//       alert("error");
//      }
//      return '';
//   }

  const handleSignUp = async (e) => {
    e.preventDefault();

    if(password !== confirmPassword){
      setMessage('*Passwords do not match');
    }else{
      // const res = await checkMailId();
      // // console.log(res);
      // if(res === 'Mail Id is new..'){
        const mess = await saveUserDetails();
        console.log(userDetails);
        setMessage(mess);
        console.log(mess);
        if(mess === "Success"){
          navigate("login");
          setMessage('');
          alert("Account created successfully!!");
        }
        // setTimeout(() => {
        // handleLogin(e);
        // }, 1000); 
        // renderPage();
      // }
    }
  }

  // Render different pages based on current page state
  const renderPage = () => {
    switch (currentPage) {
      case 'login':
        return (
          <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-300 p-4">
            <div className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-md">
              <h1 className="text-3xl font-bold text-center text-blue-800 mb-6">
                Credit Monitoring Analysis
              </h1>
              <form onSubmit={handleLogin} className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter your password"
                  />
                </div>
                <p className="block text-sm font-small text-red-400 mb-2">{message}</p>
                <div className="flex justify-between items-center">
                  <Link 
                    to="forgot-password" 
                    className="text-sm text-blue-600 hover:text-blue-800 transition duration-300"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Log In
                </button>
              </form>
              <div className="text-center mt-4">
                <span className="text-sm text-gray-600">
                  Don't have an account?{' '}
                  <Link 
                    to="signup" 
                    className="text-blue-600 hover:text-blue-800 font-semibold"
                  >
                    Sign Up
                  </Link>
                </span>
              </div>
            </div>
          </div>
        );
      
      case 'forgot-password':
        return (
          <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-300 p-4">
            <div className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-md">
              <h1 className="text-3xl font-bold text-center text-blue-800 mb-6">
                Forgot Password
              </h1>
              <form className="space-y-4">
                <div>
                  <label htmlFor="reset-email" className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    id="reset-email"
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter your email"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Reset Password
                </button>
                <div className="text-center mt-4">
                  <Link 
                    to="login" 
                    className="text-sm text-blue-600 hover:text-blue-800 transition duration-300"
                  >
                    Back to Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        );
      
      case 'signup':
        return (
          <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-300 p-4">
            <div className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-md">
              <h1 className="text-3xl font-bold text-center text-blue-800 mb-6">
                Sign Up
              </h1>
              <form onSubmit={handleSignUp} className="space-y-4">
                <div>
                  <label htmlFor="full-name" className="block text-sm font-medium text-gray-700 mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    id="full-name"
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter your full name"
                  />
                </div>
                <div>
                  <label htmlFor="signup-email" className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    id="signup-email"
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <label htmlFor="signup-password" className="block text-sm font-medium text-gray-700 mb-2">
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    id="signup-password"
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Create a password"
                  />
                </div>
                <div>
                  <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 mb-2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Confirm your password"
                  />
                </div>
                <p className="block text-sm font-small text-red-400 mb-2">{message}</p>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Create Account
                </button>
                <div className="text-center mt-4">
                  <span className="text-sm text-gray-600">
                    Already have an account?{' '}
                    <Link 
                      to="login" 
                      className="text-blue-600 hover:text-blue-800 font-semibold"
                    >
                      Log In
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        );
      case 'dashboard':
        return(
          <Dashboard userDetails={userDetails}/>
        );

      default:
        return null;
    }
  };

  return (
    <NavigationContext.Provider value={{ navigate }}>
      {renderPage()}
    </NavigationContext.Provider>
  );
};

export default LoginPage;
